import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./views/Layout.js";
import Home from "./views/Home.js";
import Legal from "./views/Legal.js";
import NoPage from "./views/NoPage.js";
import ComingSoon from "./views/ComingSoon.js";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/*
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="legal" element={<Legal />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        */}
        
        <Route path="/" element={""}>
          <Route index element={<ComingSoon />} />
          <Route path="*" element={<ComingSoon />} />
        </Route>
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
