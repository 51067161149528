/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 ..\..\..\public\models\donut_cafe\scene.gltf -r /public/models/ 
Author: Kenko_Art (https://sketchfab.com/kenko_art)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/donut-cafe-79a5afa4222942c78eed15488c9f2080
Title: Donut Cafe
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function DonutPlanet(props) {
  const { nodes, materials } = useGLTF('/models/donut_cafe/scene.gltf')
  return (
    <group {...props} dispose={null}>
      <group scale={0.01}>
        <mesh geometry={nodes.pTorus3_lambert3_0.geometry} material={materials.lambert3} position={[1.362, -4.582, -2.508]} rotation={[0.641, -0.667, 2.026]} scale={[0.119, 0.116, 0.116]} />
        <mesh geometry={nodes.pCylinder20_lambert3_0.geometry} material={materials.lambert3} position={[1.441, -4.226, -2.703]} rotation={[0.947, -0.498, 0.586]} scale={[0.189, 0.189, 0.149]} />
        <mesh geometry={nodes.pCube27_lambert3_0.geometry} material={materials.lambert3} position={[4.612, -1.659, 0.903]} scale={[0.378, 1, 0.281]} />
        <mesh geometry={nodes.pCube18_lambert3_0.geometry} material={materials.lambert3} position={[4.282, -1.684, 2.725]} rotation={[0, 0, -0.119]} scale={[0.212, 0.176, 0.242]} />
        <mesh geometry={nodes.pCylinder16_lambert3_0.geometry} material={materials.lambert3} position={[3.112, -1.712, -1.577]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.402, 1.184, 0.402]} />
        <mesh geometry={nodes.pCube28_lambert3_0.geometry} material={materials.lambert3} position={[4.612, -1.659, 2.155]} scale={[0.378, 1, 0.281]} />
        <mesh geometry={nodes.pCube31_lambert3_0.geometry} material={materials.lambert3} position={[-4.331, -1.526, 0.584]} rotation={[0, Math.PI / 2, 0]} scale={[0.588, 0.622, 0.187]} />
        <mesh geometry={nodes.pCube40_lambert3_0.geometry} material={materials.lambert3} position={[-2.997, -2.822, 1.25]} rotation={[-Math.PI, -0.202, -Math.PI]} scale={[0.147, 0.684, 0.187]} />
        <mesh geometry={nodes.pCylinder37_lambert3_0.geometry} material={materials.lambert3} position={[-2.084, -3.012, -1.487]} rotation={[0, -0.475, 0]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder26_lambert3_0.geometry} material={materials.lambert3} position={[-2.845, -2.594, -2.202]} rotation={[0, -0.475, 0]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder25_lambert3_0.geometry} material={materials.lambert3} position={[-3.372, -3.318, -2.065]} rotation={[-0.753, 0.156, -1.49]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder22_lambert3_0.geometry} material={materials.lambert3} position={[0.542, -1.43, -2.229]} rotation={[-0.475, 0, -Math.PI / 2]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder27_lambert3_0.geometry} material={materials.lambert3} position={[-2.084, -2.187, -1.487]} rotation={[0, -0.475, 0]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder23_lambert3_0.geometry} material={materials.lambert3} position={[-0.223, -1.415, -2.221]} rotation={[-0.475, 0, -Math.PI / 2]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder39_lambert3_0.geometry} material={materials.lambert3} position={[-1.951, 6.267, -1.45]} rotation={[0, -0.475, 0]} scale={[0.169, 0.084, 0.169]} />
        <mesh geometry={nodes.pCylinder55_lambert3_0.geometry} material={materials.lambert3} position={[-4.24, 6.301, 0.966]} rotation={[0, -0.475, 0]} scale={[0.2, 0.099, 0.2]} />
        <mesh geometry={nodes.pCylinder56_lambert3_0.geometry} material={materials.lambert3} position={[-2.321, 5.869, -1.45]} rotation={[0, -0.475, 0]} scale={[0.169, 0.084, 0.169]} />
        <mesh geometry={nodes.pCube38_lambert3_0.geometry} material={materials.lambert3} position={[2.363, -1.746, 1.517]} rotation={[-Math.PI, 1.387, -Math.PI]} scale={[0.588, 0.997, 0.187]} />
        <mesh geometry={nodes.pCylinder38_lambert3_0.geometry} material={materials.lambert3} position={[1.467, -7.261, 1.262]} rotation={[-0.692, -0.771, -2.095]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCube30_lambert3_0.geometry} material={materials.lambert3} position={[-5.132, -1.526, 0.584]} rotation={[0, Math.PI / 2, 0]} scale={[0.588, 0.622, 0.187]} />
        <mesh geometry={nodes.pCube24_lambert3_0.geometry} material={materials.lambert3} position={[0.681, -8.044, -0.412]} rotation={[0, 0.339, 0]} scale={[0.069, 0.571, 0.11]} />
        <mesh geometry={nodes.pSphere2_lambert3_0.geometry} material={materials.lambert3} position={[1.827, -10.231, -0.565]} rotation={[0, 0, -1.75]} scale={[0.084, 0.093, 0.084]} />
        <mesh geometry={nodes.pCylinder17_lambert3_0.geometry} material={materials.lambert3} position={[1.027, -10.107, -0.564]} rotation={[-0.021, -0.115, -1.751]} scale={[0.269, 0.153, 0.269]} />
        <mesh geometry={nodes.pCube29_lambert3_0.geometry} material={materials.lambert3} position={[0.87, -8.232, -0.448]} scale={[0.229, 0.948, 0.229]} />
        <mesh geometry={nodes.pSphere1_lambert3_0.geometry} material={materials.lambert3} position={[0.659, -5.397, -0.761]} rotation={[-Math.PI / 2, 0, 0.154]} scale={[0.535, 0.455, 0.535]} />
        <mesh geometry={nodes.sweep19_lambert3_0.geometry} material={materials.lambert3} position={[3.036, -2.895, 0.351]} rotation={[0.011, -0.307, 0.036]} scale={0.308} />
        <mesh geometry={nodes.pCube22_lambert3_0.geometry} material={materials.lambert3} position={[1.512, -10.195, -0.565]} rotation={[0, 0, -0.179]} scale={[0.962, 0.024, 0.024]} />
        <mesh geometry={nodes.pCube21_lambert3_0.geometry} material={materials.lambert3} position={[0.794, -9.09, -0.552]} scale={[0.11, 0.611, 0.11]} />
        <mesh geometry={nodes.sweep10_lambert3_0.geometry} material={materials.lambert3} position={[9.181, -2.833, 1.738]} rotation={[0, -0.238, 0]} scale={0.343} />
        <mesh geometry={nodes.sweep22_lambert3_0.geometry} material={materials.lambert3} position={[5.813, -5.624, -2.968]} rotation={[0, 0.495, 0]} scale={[0.172, 0.172, 0.13]} />
        <mesh geometry={nodes.pCylinder2_lambert3_0.geometry} material={materials.lambert3} position={[-1.073, -4.07, -0.758]} scale={[0.615, 0.855, 0.615]} />
        <mesh geometry={nodes.sweep11_lambert3_0.geometry} material={materials.lambert3} position={[0.541, -1.425, 3.145]} rotation={[0, -0.886, -0.304]} scale={0.172} />
        <mesh geometry={nodes.sweep8_lambert3_0.geometry} material={materials.lambert3} position={[5.319, -1.904, 1.877]} rotation={[0, -0.107, 0]} scale={[0.281, 0.358, 0.358]} />
        <mesh geometry={nodes.sweep30_lambert3_0.geometry} material={materials.lambert3} />
        <mesh geometry={nodes.pCube14_lambert3_0.geometry} material={materials.lambert3} position={[3.655, -4.495, 1.066]} rotation={[0, 0.174, 0]} scale={[0.267, 1.376, 1.38]} />
        <mesh geometry={nodes.pCube32_lambert3_0.geometry} material={materials.lambert3} position={[0.031, -3.267, -1.14]} rotation={[0, 0.355, 0]} scale={[1.534, 0.104, 0.895]} />
        <mesh geometry={nodes.pCube26_lambert3_0.geometry} material={materials.lambert3} position={[4.612, -1.659, -1.903]} scale={[0.378, 1, 0.281]} />
        <mesh geometry={nodes.sweep4_lambert3_0.geometry} material={materials.lambert3} position={[4.556, -2.003, 2.732]} rotation={[0, -0.51, 0]} scale={0.435} />
        <mesh geometry={nodes.sweep9_lambert3_0.geometry} material={materials.lambert3} position={[6.91, -1.563, 7.953]} rotation={[0, -0.887, 0]} scale={0.427} />
        <mesh geometry={nodes.pCylinder19_lambert3_0.geometry} material={materials.lambert3} position={[1.441, -4.226, -2.703]} rotation={[0.947, -0.498, 0.586]} scale={[0.189, 0.189, 0.149]} />
        <mesh geometry={nodes.sweep29_lambert3_0.geometry} material={materials.lambert3} />
        <mesh geometry={nodes.pCube19_lambert3_0.geometry} material={materials.lambert3} position={[4.282, -1.684, -2.477]} rotation={[0, 0, -0.119]} scale={[0.212, 0.176, 0.253]} />
        <mesh geometry={nodes.pCube33_lambert3_0.geometry} material={materials.lambert3} position={[-0.256, -4.381, -1.459]} rotation={[0, 0.671, 0]} scale={[1.534, 0.114, 0.895]} />
        <mesh geometry={nodes.pCylinder28_lambert3_0.geometry} material={materials.lambert3} position={[-2.638, -4.599, -1.05]} scale={[0.042, 1, 0.042]} />
        <mesh geometry={nodes.pCube35_lambert3_0.geometry} material={materials.lambert3} position={[-2.937, -3.573, 1.625]} rotation={[0, 0.174, 0]} scale={[0.967, 0.967, 1.548]} />
        <mesh geometry={nodes.sweep3_lambert3_0.geometry} material={materials.lambert3} position={[4.002, -0.669, -5.122]} rotation={[0, 0.514, 0]} scale={0.79} />
        <mesh geometry={nodes.sweep12_lambert3_0.geometry} material={materials.lambert3} position={[4.562, -0.058, 3.077]} rotation={[0.008, -0.523, -0.012]} scale={0.28} />
        <mesh geometry={nodes.pTorus2_lambert3_0.geometry} material={materials.lambert3} position={[1.362, -4.582, -2.508]} rotation={[0.641, -0.667, 2.026]} scale={[0.119, 0.116, 0.116]} />
        <mesh geometry={nodes.pCube12_lambert3_0.geometry} material={materials.lambert3} position={[1.684, -3.705, -1.522]} rotation={[0, -0.825, 0]} scale={[2.887, 1.895, 0.285]} />
        <mesh geometry={nodes.pCube25_lambert3_0.geometry} material={materials.lambert3} position={[3.48, -4.843, 1.125]} rotation={[0, 0.168, 0]} scale={[0.162, 0.264, 0.186]} />
        <mesh geometry={nodes.pCylinder15_lambert3_0.geometry} material={materials.lambert3} position={[3.961, -1.712, -1.577]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.402, 1.184, 0.402]} />
        <mesh geometry={nodes.pCube16_lambert3_0.geometry} material={materials.lambert3} position={[4.606, -1.58, 0.429]} scale={[0.177, 0.839, 4.576]} />
        <mesh geometry={nodes.sweep20_lambert3_0.geometry} material={materials.lambert3} position={[7.6, -2.402, -0.303]} scale={0.291} />
        <mesh geometry={nodes.sweep7_lambert3_0.geometry} material={materials.lambert3} position={[3.078, -2.392, 11.113]} rotation={[-Math.PI, -1.497, -Math.PI]} scale={0.399} />
        <mesh geometry={nodes.sweep2_lambert3_0.geometry} material={materials.lambert3} position={[3.56, -4.201, -2.825]} rotation={[-0.073, 0.3, -0.019]} scale={0.566} />
        <mesh geometry={nodes.pCube13_lambert3_0.geometry} material={materials.lambert3} position={[3.655, -2.896, 1.066]} rotation={[0, 0.174, 0]} scale={[0.242, 0.839, 1.38]} />
        <mesh geometry={nodes.sweep6_lambert3_0.geometry} material={materials.lambert3} position={[7.288, 0.086, 10.724]} rotation={[0, -1.198, 0]} scale={0.816} />
        <mesh geometry={nodes.pCylinder54_lambert3_0.geometry} material={materials.lambert3} position={[-3.208, -2.86, -0.969]} rotation={[Math.PI / 2, 0, 0.034]} scale={0.185} />
        <mesh geometry={nodes.sweep23_lambert3_0.geometry} material={materials.lambert3} position={[3.792, -2.385, 5.815]} rotation={[0, -Math.PI / 2, 0]} scale={0.114} />
        <mesh geometry={nodes.pCylinder31_lambert3_0.geometry} material={materials.lambert3} position={[-0.692, -3.807, -1.692]} rotation={[0, 0.445, Math.PI / 2]} scale={[0.102, 1, 0.102]} />
        <mesh geometry={nodes.pCube36_lambert3_0.geometry} material={materials.lambert3} position={[-2.491, -1.857, 1.604]} rotation={[0, 0.174, 0]} scale={[1.825, 1.305, 2.409]} />
        <mesh geometry={nodes.pCylinder29_lambert3_0.geometry} material={materials.lambert3} position={[0.239, -2.784, 1.947]} scale={[0.615, 1.205, 0.615]} />
        <mesh geometry={nodes.pCube17_lambert3_0.geometry} material={materials.lambert3} position={[0.243, -1.807, -1.481]} scale={[2.206, 1.211, 0.437]} />
        <mesh geometry={nodes.sweep5_lambert3_0.geometry} material={materials.lambert3} position={[0, 0, 7.422]} />
        <mesh geometry={nodes.pCylinder18_lambert3_0.geometry} material={materials.lambert3} position={[-2.776, -3.652, -1.472]} rotation={[0, -0.291, 0]} scale={[0.53, 1, 0.53]} />
        <mesh geometry={nodes.sweep31_lambert3_0.geometry} material={materials.lambert3} />
        <mesh geometry={nodes.sweep1_lambert3_0.geometry} material={materials.lambert3} position={[7.591, -6.893, 4.026]} rotation={[0, -0.397, 0]} scale={0.778} />
        <mesh geometry={nodes.pCube39_lambert3_0.geometry} material={materials.lambert3} position={[-2.9, -2.822, 1.995]} rotation={[-Math.PI, -0.202, -Math.PI]} scale={[0.147, 0.684, 0.187]} />
        <mesh geometry={nodes.pCylinder21_lambert3_0.geometry} material={materials.lambert3} position={[-1.202, -2.224, -0.653]} rotation={[-Math.PI, -1.126, -Math.PI]} scale={[0.199, 0.855, 0.199]} />
        <mesh geometry={nodes.sweep28_lambert3_0.geometry} material={materials.lambert3} />
        <mesh geometry={nodes.pCylinder1_lambert3_0.geometry} material={materials.lambert3} position={[-2.79, -2.485, -1.478]} scale={[0.694, 0.965, 0.694]} />
        <mesh geometry={nodes.pCube15_lambert3_0.geometry} material={materials.lambert3} position={[-4.937, -2.36, 0.133]} scale={[2.206, 1.072, 0.896]} />
        <mesh geometry={nodes.pCylinder4_lambert3_0.geometry} material={materials.lambert3} position={[0.851, -6.174, -0.398]} scale={[0.522, 1.182, 0.522]} />
        <mesh geometry={nodes.pCube3_lambert3_0.geometry} material={materials.lambert3} position={[2.073, -3.205, 1.354]} rotation={[0, 0.174, 0]} scale={2.158} />
        <mesh geometry={nodes.pCube2_lambert3_0.geometry} material={materials.lambert3} position={[0, -1.236, 0]} scale={[9.845, 0.357, 4.91]} />
        <mesh geometry={nodes.pCylinder63_lambert3_0.geometry} material={materials.lambert3} position={[-0.409, -2.211, 1.867]} scale={[0.4, 0.785, 0.4]} />
        <mesh geometry={nodes.sweep21_lambert3_0.geometry} material={materials.lambert3} position={[6.37, -5.624, -0.815]} rotation={[0, 0.115, 0]} scale={[0.172, 0.172, 0.138]} />
        <mesh geometry={nodes.pCylinder24_lambert3_0.geometry} material={materials.lambert3} position={[-1.805, -1.376, -2.216]} rotation={[-0.475, 0, -Math.PI / 2]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCube37_lambert3_0.geometry} material={materials.lambert3} position={[1.405, -1.746, 1.682]} rotation={[-Math.PI, 1.387, -Math.PI]} scale={[0.588, 0.997, 0.187]} />
        <group position={[-4.224, -2.72, -2.943]}>
          <primitive object={nodes._rootJoint} />
          <skinnedMesh geometry={nodes.Object_191.geometry} material={materials.lambert2} skeleton={nodes.Object_191.skeleton} />
          <skinnedMesh geometry={nodes.Object_193.geometry} material={materials.lambert2} skeleton={nodes.Object_193.skeleton} />
          <skinnedMesh geometry={nodes.Object_195.geometry} material={materials.lambert2} skeleton={nodes.Object_195.skeleton} />
          <skinnedMesh geometry={nodes.Object_197.geometry} material={materials.lambert2} skeleton={nodes.Object_197.skeleton} />
          <skinnedMesh geometry={nodes.Object_199.geometry} material={materials.lambert2} skeleton={nodes.Object_199.skeleton} />
          <skinnedMesh geometry={nodes.Object_201.geometry} material={materials.lambert2} skeleton={nodes.Object_201.skeleton} />
          <skinnedMesh geometry={nodes.Object_203.geometry} material={materials.lambert2} skeleton={nodes.Object_203.skeleton} />
          <skinnedMesh geometry={nodes.Object_205.geometry} material={materials.lambert2} skeleton={nodes.Object_205.skeleton} />
          <skinnedMesh geometry={nodes.Object_207.geometry} material={materials.lambert2} skeleton={nodes.Object_207.skeleton} />
          <skinnedMesh geometry={nodes.Object_209.geometry} material={materials.lambert2} skeleton={nodes.Object_209.skeleton} />
          <skinnedMesh geometry={nodes.Object_211.geometry} material={materials.lambert2} skeleton={nodes.Object_211.skeleton} />
          <skinnedMesh geometry={nodes.Object_213.geometry} material={materials.lambert2} skeleton={nodes.Object_213.skeleton} />
          <skinnedMesh geometry={nodes.Object_215.geometry} material={materials.lambert2} skeleton={nodes.Object_215.skeleton} />
          <skinnedMesh geometry={nodes.Object_217.geometry} material={materials.lambert2} skeleton={nodes.Object_217.skeleton} />
          <skinnedMesh geometry={nodes.Object_219.geometry} material={materials.lambert2} skeleton={nodes.Object_219.skeleton} />
          <skinnedMesh geometry={nodes.Object_221.geometry} material={materials.lambert2} skeleton={nodes.Object_221.skeleton} />
          <skinnedMesh geometry={nodes.Object_223.geometry} material={materials.lambert2} skeleton={nodes.Object_223.skeleton} />
          <skinnedMesh geometry={nodes.Object_225.geometry} material={materials.lambert2} skeleton={nodes.Object_225.skeleton} />
          <skinnedMesh geometry={nodes.Object_227.geometry} material={materials.lambert2} skeleton={nodes.Object_227.skeleton} />
          <skinnedMesh geometry={nodes.Object_229.geometry} material={materials.lambert2} skeleton={nodes.Object_229.skeleton} />
          <skinnedMesh geometry={nodes.Object_231.geometry} material={materials.lambert2} skeleton={nodes.Object_231.skeleton} />
          <skinnedMesh geometry={nodes.Object_233.geometry} material={materials.lambert2} skeleton={nodes.Object_233.skeleton} />
          <skinnedMesh geometry={nodes.Object_235.geometry} material={materials.lambert2} skeleton={nodes.Object_235.skeleton} />
          <skinnedMesh geometry={nodes.Object_237.geometry} material={materials.lambert2} skeleton={nodes.Object_237.skeleton} />
          <skinnedMesh geometry={nodes.Object_239.geometry} material={materials.lambert2} skeleton={nodes.Object_239.skeleton} />
          <skinnedMesh geometry={nodes.Object_241.geometry} material={materials.lambert2} skeleton={nodes.Object_241.skeleton} />
          <skinnedMesh geometry={nodes.Object_243.geometry} material={materials.lambert2} skeleton={nodes.Object_243.skeleton} />
          <skinnedMesh geometry={nodes.Object_245.geometry} material={materials.lambert2} skeleton={nodes.Object_245.skeleton} />
          <skinnedMesh geometry={nodes.Object_247.geometry} material={materials.lambert2} skeleton={nodes.Object_247.skeleton} />
          <skinnedMesh geometry={nodes.Object_249.geometry} material={materials.lambert2} skeleton={nodes.Object_249.skeleton} />
          <skinnedMesh geometry={nodes.Object_251.geometry} material={materials.lambert2} skeleton={nodes.Object_251.skeleton} />
          <skinnedMesh geometry={nodes.Object_253.geometry} material={materials.lambert2} skeleton={nodes.Object_253.skeleton} />
          <skinnedMesh geometry={nodes.Object_255.geometry} material={materials.lambert2} skeleton={nodes.Object_255.skeleton} />
          <skinnedMesh geometry={nodes.Object_257.geometry} material={materials.lambert2} skeleton={nodes.Object_257.skeleton} />
          <skinnedMesh geometry={nodes.Object_259.geometry} material={materials.lambert2} skeleton={nodes.Object_259.skeleton} />
          <skinnedMesh geometry={nodes.Object_261.geometry} material={materials.lambert2} skeleton={nodes.Object_261.skeleton} />
          <skinnedMesh geometry={nodes.Object_263.geometry} material={materials.lambert4} skeleton={nodes.Object_263.skeleton} />
          <skinnedMesh geometry={nodes.Object_265.geometry} material={materials.lambert4} skeleton={nodes.Object_265.skeleton} />
          <skinnedMesh geometry={nodes.Object_267.geometry} material={materials.lambert4} skeleton={nodes.Object_267.skeleton} />
          <skinnedMesh geometry={nodes.Object_269.geometry} material={materials.lambert4} skeleton={nodes.Object_269.skeleton} />
          <skinnedMesh geometry={nodes.Object_271.geometry} material={materials.lambert4} skeleton={nodes.Object_271.skeleton} />
          <skinnedMesh geometry={nodes.Object_273.geometry} material={materials.lambert4} skeleton={nodes.Object_273.skeleton} />
          <skinnedMesh geometry={nodes.Object_275.geometry} material={materials.lambert4} skeleton={nodes.Object_275.skeleton} />
          <skinnedMesh geometry={nodes.Object_277.geometry} material={materials.lambert4} skeleton={nodes.Object_277.skeleton} />
          <skinnedMesh geometry={nodes.Object_279.geometry} material={materials.lambert4} skeleton={nodes.Object_279.skeleton} />
          <skinnedMesh geometry={nodes.Object_281.geometry} material={materials.lambert4} skeleton={nodes.Object_281.skeleton} />
          <skinnedMesh geometry={nodes.Object_283.geometry} material={materials.lambert4} skeleton={nodes.Object_283.skeleton} />
          <skinnedMesh geometry={nodes.Object_285.geometry} material={materials.lambert4} skeleton={nodes.Object_285.skeleton} />
          <skinnedMesh geometry={nodes.Object_287.geometry} material={materials.lambert4} skeleton={nodes.Object_287.skeleton} />
          <skinnedMesh geometry={nodes.Object_289.geometry} material={materials.lambert4} skeleton={nodes.Object_289.skeleton} />
          <skinnedMesh geometry={nodes.Object_291.geometry} material={materials.lambert4} skeleton={nodes.Object_291.skeleton} />
          <skinnedMesh geometry={nodes.Object_293.geometry} material={materials.lambert4} skeleton={nodes.Object_293.skeleton} />
          <skinnedMesh geometry={nodes.Object_295.geometry} material={materials.lambert4} skeleton={nodes.Object_295.skeleton} />
          <skinnedMesh geometry={nodes.Object_297.geometry} material={materials.lambert4} skeleton={nodes.Object_297.skeleton} />
          <skinnedMesh geometry={nodes.Object_299.geometry} material={materials.lambert4} skeleton={nodes.Object_299.skeleton} />
          <skinnedMesh geometry={nodes.Object_301.geometry} material={materials.lambert4} skeleton={nodes.Object_301.skeleton} />
          <skinnedMesh geometry={nodes.Object_303.geometry} material={materials.lambert4} skeleton={nodes.Object_303.skeleton} />
          <skinnedMesh geometry={nodes.Object_305.geometry} material={materials.lambert4} skeleton={nodes.Object_305.skeleton} />
          <skinnedMesh geometry={nodes.Object_307.geometry} material={materials.lambert4} skeleton={nodes.Object_307.skeleton} />
          <skinnedMesh geometry={nodes.Object_309.geometry} material={materials.lambert4} skeleton={nodes.Object_309.skeleton} />
          <skinnedMesh geometry={nodes.Object_311.geometry} material={materials.lambert4} skeleton={nodes.Object_311.skeleton} />
          <skinnedMesh geometry={nodes.Object_313.geometry} material={materials.lambert4} skeleton={nodes.Object_313.skeleton} />
          <skinnedMesh geometry={nodes.Object_315.geometry} material={materials.lambert4} skeleton={nodes.Object_315.skeleton} />
          <skinnedMesh geometry={nodes.Object_317.geometry} material={materials.lambert4} skeleton={nodes.Object_317.skeleton} />
          <skinnedMesh geometry={nodes.Object_319.geometry} material={materials.lambert4} skeleton={nodes.Object_319.skeleton} />
          <skinnedMesh geometry={nodes.Object_321.geometry} material={materials.lambert4} skeleton={nodes.Object_321.skeleton} />
          <skinnedMesh geometry={nodes.Object_323.geometry} material={materials.lambert4} skeleton={nodes.Object_323.skeleton} />
          <skinnedMesh geometry={nodes.Object_325.geometry} material={materials.lambert4} skeleton={nodes.Object_325.skeleton} />
          <skinnedMesh geometry={nodes.Object_327.geometry} material={materials.lambert4} skeleton={nodes.Object_327.skeleton} />
          <skinnedMesh geometry={nodes.Object_329.geometry} material={materials.lambert4} skeleton={nodes.Object_329.skeleton} />
          <skinnedMesh geometry={nodes.Object_331.geometry} material={materials.lambert4} skeleton={nodes.Object_331.skeleton} />
          <skinnedMesh geometry={nodes.Object_333.geometry} material={materials.lambert4} skeleton={nodes.Object_333.skeleton} />
        </group>
        <mesh geometry={nodes.pTorus8_lambert5_0.geometry} material={materials.lambert5} position={[-5.232, -0.638, -2.637]} rotation={[-2.824, 0.785, 0]} scale={[0.108, 0.108, 0.11]} />
        <mesh geometry={nodes.pTorus7_lambert5_0.geometry} material={materials.lambert5} position={[-5.232, -0.85, -2.784]} rotation={[2.911, 0.785, 0]} scale={[0.108, 0.108, 0.11]} />
        <mesh geometry={nodes.pTorus6_lambert5_0.geometry} material={materials.lambert5} position={[-5.232, -0.995, -2.629]} rotation={[-3.014, 0.785, 0]} scale={[0.108, 0.108, 0.11]} />
        <mesh geometry={nodes.pCylinder32_lambert5_0.geometry} material={materials.lambert5} position={[-0.683, 6.737, 0.645]} scale={[0.233, 0.239, 0.217]} />
        <mesh geometry={nodes.polySurface14_lambert5_0.geometry} material={materials.lambert5} position={[-0.009, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.polySurface13_lambert5_0.geometry} material={materials.lambert5} position={[-0.009, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.polySurface12_lambert5_0.geometry} material={materials.lambert5} position={[-0.009, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.pCube51_lambert5_0.geometry} material={materials.lambert5} position={[-3.177, 4.996, -1.49]} scale={[1.279, 0.701, 0.461]} />
        <mesh geometry={nodes.pCube43_lambert5_0.geometry} material={materials.lambert5} position={[3.905, 2.33, 1.749]} rotation={[0, 0, -1.018]} scale={[0.284, 0.564, 0.199]} />
        <mesh geometry={nodes.pTorus4_lambert5_0.geometry} material={materials.lambert5} position={[0.337, -8.292, -1.438]} rotation={[-Math.PI / 2, 0, 0.24]} scale={[0.137, 0.137, 0.148]} />
        <mesh geometry={nodes.pCube44_lambert5_0.geometry} material={materials.lambert5} position={[3.905, 2.33, 0.935]} rotation={[0, 0, -1.018]} scale={[0.284, 0.564, 0.199]} />
        <mesh geometry={nodes.pCube50_lambert5_0.geometry} material={materials.lambert5} position={[-3.177, 5.766, -1.49]} scale={[1.279, 0.701, 0.461]} />
        <mesh geometry={nodes.sweep35_lambert5_0.geometry} material={materials.lambert5} position={[-0.188, 1.488, 0.291]} rotation={[-0.055, 0, 0]} />
        <mesh geometry={nodes.sweep33_lambert5_0.geometry} material={materials.lambert5} position={[0, 1.55, 0.65]} rotation={[-0.108, 0, 0]} />
        <mesh geometry={nodes.pCube78_lambert5_0.geometry} material={materials.lambert5} position={[-2.935, -0.965, 3.213]} scale={[1.339, 0.62, 0.757]} />
        <mesh geometry={nodes.pCylinder53_lambert5_0.geometry} material={materials.lambert5} position={[-3.054, 6.322, 1.318]} rotation={[Math.PI / 2, 0, 0]} scale={0.107} />
        <mesh geometry={nodes.pCylinder52_lambert5_0.geometry} material={materials.lambert5} position={[-3.398, 6.768, 0.961]} rotation={[0, 0, Math.PI / 2]} scale={0.178} />
        <mesh geometry={nodes.sweep32_lambert5_0.geometry} material={materials.lambert5} position={[0, 0.206, 0]} />
        <mesh geometry={nodes.sweep27_lambert5_0.geometry} material={materials.lambert5} />
        <mesh geometry={nodes.sweep26_lambert5_0.geometry} material={materials.lambert5} />
        <mesh geometry={nodes.sweep25_lambert5_0.geometry} material={materials.lambert5} />
        <mesh geometry={nodes.sweep24_lambert5_0.geometry} material={materials.lambert5} position={[0, 0.206, 0]} />
        <mesh geometry={nodes.pPlane7_lambert5_0.geometry} material={materials.lambert5} position={[4.625, -0.667, -1.102]} rotation={[0, 0.571, -Math.PI / 2]} scale={[0.901, 0.902, 0.902]} />
        <mesh geometry={nodes.pPlane6_lambert5_0.geometry} material={materials.lambert5} position={[6.983, 12.791, 0.784]} rotation={[-Math.PI / 2, 0, 0]} scale={[1.077, 0.745, 1.015]} />
        <mesh geometry={nodes.pPlane5_lambert5_0.geometry} material={materials.lambert5} position={[-7.588, 13.375, -0.094]} rotation={[-Math.PI / 2, 0, 0]} scale={[1.445, 1, 1.903]} />
        <mesh geometry={nodes.pPlane4_lambert5_0.geometry} material={materials.lambert5} position={[-4.752, 11.853, -0.094]} rotation={[-Math.PI / 2, 0, 0]} scale={[1.445, 1, 1.169]} />
        <mesh geometry={nodes.pPlane3_lambert5_0.geometry} material={materials.lambert5} position={[-6.318, 10.367, -0.094]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.939, 1, 0.895]} />
        <mesh geometry={nodes.pPlane2_lambert5_0.geometry} material={materials.lambert5} position={[4.353, -0.398, 3.21]} rotation={[0, 0.683, -Math.PI / 2]} scale={[1.523, 0.902, 0.902]} />
        <mesh geometry={nodes.pPlane1_lambert5_0.geometry} material={materials.lambert5} position={[-1.883, -0.606, -2.58]} rotation={[-Math.PI / 2, 0, -0.44]} scale={[1.571, 1, 1]} />
        <mesh geometry={nodes.sweep18_lambert5_0.geometry} material={materials.lambert5} position={[0.012, 0, 0]} />
        <mesh geometry={nodes.pCube52_lambert5_0.geometry} material={materials.lambert5} position={[3.401, 5.273, 2.81]} scale={[1.51, 0.699, 0.551]} />
        <mesh geometry={nodes.pCylinder36_lambert5_0.geometry} material={materials.lambert5} position={[-1.954, 6.125, -1.455]} scale={[0.103, 0.326, 0.103]} />
        <mesh geometry={nodes.pCylinder35_lambert5_0.geometry} material={materials.lambert5} position={[-2.332, 6.125, -1.455]} scale={[0.103, 0.326, 0.103]} />
        <mesh geometry={nodes.sweep17_lambert5_0.geometry} material={materials.lambert5} position={[-1.946, 10.523, -1.259]} scale={0.123} />
        <mesh geometry={nodes.sweep16_lambert5_0.geometry} material={materials.lambert5} position={[-5.101, 12.172, -0.288]} rotation={[0, 0, 0.091]} scale={0.125} />
        <mesh geometry={nodes.pSphere3_lambert5_0.geometry} material={materials.lambert5} position={[0.047, 11.609, -0.303]} rotation={[-Math.PI, -1.2, -Math.PI]} scale={0.198} />
        <mesh geometry={nodes.sweep15_lambert5_0.geometry} material={materials.lambert5} position={[-1.932, 14.749, -0.28]} rotation={[0, 0, -0.116]} scale={0.267} />
        <mesh geometry={nodes.pCylinder34_lambert5_0.geometry} material={materials.lambert5} position={[-3.881, 7.618, -0.377]} scale={0.055} />
        <mesh geometry={nodes.pCylinder33_lambert5_0.geometry} material={materials.lambert5} position={[-0.683, 6.953, -0.033]} scale={[0.233, 0.239, 0.217]} />
        <mesh geometry={nodes.pCube48_lambert5_0.geometry} material={materials.lambert5} position={[-1.508, 9.293, 0.346]} scale={[0.278, 0.19, 0.078]} />
        <mesh geometry={nodes.pCube47_lambert5_0.geometry} material={materials.lambert5} position={[-1.652, 9.959, 0.346]} scale={[0.509, 0.154, 0.048]} />
        <mesh geometry={nodes.sweep14_lambert5_0.geometry} material={materials.lambert5} position={[3.248, 2.822, 1.033]} rotation={[0, -0.942, 0.196]} scale={0.112} />
        <mesh geometry={nodes.pCube46_lambert5_0.geometry} material={materials.lambert5} position={[-0.471, 3.159, 0.746]} scale={[8.583, 1, 4.039]} />
        <mesh geometry={nodes.pCube45_lambert5_0.geometry} material={materials.lambert5} position={[-1.323, 2.607, -1.624]} scale={[0.961, 0.842, 0.504]} />
        <mesh geometry={nodes.sweep13_lambert5_0.geometry} material={materials.lambert5} position={[2.114, 8.649, 0.644]} rotation={[0, 0, -0.105]} scale={0.135} />
        <mesh geometry={nodes.pCube42_lambert5_0.geometry} material={materials.lambert5} position={[2.398, -0.377, 2.485]} scale={[1.176, 1.477, 1.804]} />
        <mesh geometry={nodes.pCube41_lambert5_0.geometry} material={materials.lambert5} position={[-2.635, 1.462, 3.292]} scale={[1.818, 0.842, 1.028]} />
        <mesh geometry={nodes.pCube23_lambert5_0.geometry} material={materials.lambert5} position={[-0.795, 6.507, 2.001]} scale={[1.95, 0.704, 1.247]} />
        <mesh geometry={nodes.pCube20_lambert5_0.geometry} material={materials.lambert5} position={[-0.851, 8.298, 2.028]} rotation={[0, -0.141, 0]} scale={[1, 0.24, 0.917]} />
        <mesh geometry={nodes.pCylinder14_lambert5_0.geometry} material={materials.lambert5} position={[-2.665, 8.175, 0.414]} rotation={[-0.103, 0, 0]} scale={[0.061, 0.06, 0.061]} />
        <mesh geometry={nodes.pCylinder13_lambert5_0.geometry} material={materials.lambert5} position={[-1.608, 8.227, 0.345]} scale={[0.061, 0.087, 0.061]} />
        <mesh geometry={nodes.pCylinder12_lambert5_0.geometry} material={materials.lambert5} position={[4.035, 2.614, 1.533]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.336, 0.628, 0.336]} />
        <mesh geometry={nodes.pTorus5_lambert5_0.geometry} material={materials.lambert5} position={[-2.82, -0.706, -3.079]} rotation={[-Math.PI, -0.078, -1.964]} scale={[0.108, 0.108, 0.11]} />
        <mesh geometry={nodes.pCylinder11_lambert5_0.geometry} material={materials.lambert5} position={[3.992, 9.298, 0.647]} rotation={[-Math.PI, Math.PI / 4, -Math.PI]} scale={[0.079, 0.06, 0.079]} />
        <mesh geometry={nodes.pCylinder10_lambert5_0.geometry} material={materials.lambert5} position={[-0.338, 9.619, 2.018]} rotation={[-0.306, -0.361, -0.988]} scale={[0.025, 0.485, 0.025]} />
        <mesh geometry={nodes.pCylinder9_lambert5_0.geometry} material={materials.lambert5} position={[-0.44, 9.532, 2.093]} rotation={[-0.244, -0.176, -0.998]} scale={[1.164, 0.251, 1.164]} />
        <mesh geometry={nodes.pCylinder8_lambert5_0.geometry} material={materials.lambert5} position={[0.047, 6.298, -0.303]} scale={[0.073, 0.212, 0.073]} />
        <mesh geometry={nodes.pCylinder7_lambert5_0.geometry} material={materials.lambert5} position={[-6.841, 7.78, 0.059]} rotation={[0, 0.137, Math.PI / 2]} scale={[0.074, 1.12, 0.074]} />
        <mesh geometry={nodes.pCylinder6_lambert5_0.geometry} material={materials.lambert5} position={[-4.252, 7.252, 0.177]} rotation={[0, -0.257, Math.PI / 2]} scale={[0.083, 1.527, 0.083]} />
        <mesh geometry={nodes.pCylinder5_lambert5_0.geometry} material={materials.lambert5} position={[-4.418, 7.957, -0.124]} rotation={[0.229, 0.266, 0.873]} scale={[0.084, 1.527, 0.084]} />
        <mesh geometry={nodes.pCylinder3_lambert5_0.geometry} material={materials.lambert5} position={[3.332, 7.63, -0.432]} scale={[0.302, 0.72, 0.302]} />
        <mesh geometry={nodes.pCube11_lambert5_0.geometry} material={materials.lambert5} position={[4.968, 9.188, 0.647]} scale={[2.345, 0.183, 0.203]} />
        <mesh geometry={nodes.pCube10_lambert5_0.geometry} material={materials.lambert5} position={[4.968, 6.734, 0.647]} scale={[2.345, 0.183, 0.203]} />
        <mesh geometry={nodes.pCube9_lambert5_0.geometry} material={materials.lambert5} position={[6.724, 6.967, 0.719]} scale={[1.549, 5.073, 0.622]} />
        <mesh geometry={nodes.pCube8_lambert5_0.geometry} material={materials.lambert5} position={[3.588, 6.945, 1.935]} scale={[1.241, 1.934, 1.334]} />
        <mesh geometry={nodes.pCube7_lambert5_0.geometry} material={materials.lambert5} position={[-2.251, 6.891, 0.403]} scale={[2.318, 1.887, 1.674]} />
        <mesh geometry={nodes.pTorus1_lambert5_0.geometry} material={materials.lambert5} position={[-5.976, 8.494, 0]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={[1.029, 0.79, 0.909]} />
        <mesh geometry={nodes.pCube6_lambert5_0.geometry} material={materials.lambert5} position={[3.705, 7.145, -0.222]} scale={[1.847, 1.729, 1.449]} />
        <mesh geometry={nodes.pCube5_lambert5_0.geometry} material={materials.lambert5} position={[0.123, 5.892, 0.703]} scale={[8.287, 3.04, 3.38]} />
        <mesh geometry={nodes.pCube4_lambert5_0.geometry} material={materials.lambert5} position={[0.413, 2.926, 0.703]} scale={[9.779, 0.35, 7.311]} />
        <mesh geometry={nodes.polySurface1_lambert5_0.geometry} material={materials.lambert5} position={[-0.225, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.polySurface2_lambert5_0.geometry} material={materials.lambert5} position={[-0.225, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.polySurface4_lambert5_0.geometry} material={materials.lambert5} position={[-0.225, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.polySurface5_lambert5_0.geometry} material={materials.lambert5} position={[-0.225, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.polySurface6_lambert5_0.geometry} material={materials.lambert5} position={[-0.225, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.polySurface7_lambert5_0.geometry} material={materials.lambert5} position={[-0.225, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.polySurface8_lambert5_0.geometry} material={materials.lambert5} position={[-0.225, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.polySurface9_lambert5_0.geometry} material={materials.lambert5} position={[-0.225, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.polySurface11_lambert5_0.geometry} material={materials.lambert5} position={[-0.225, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.pPlane10_lambert5_0.geometry} material={materials.lambert5} position={[-2.257, -7.878, -2.674]} rotation={[-1.303, 0.046, -0.453]} scale={[0.827, 1, 0.905]} />
        <mesh geometry={nodes.pPlane9_lambert5_0.geometry} material={materials.lambert5} position={[-0.715, -7.219, -1.283]} rotation={[-1.523, 0.092, -0.48]} scale={[0.827, 1, 0.905]} />
        <mesh geometry={nodes.pPlane8_lambert5_0.geometry} material={materials.lambert5} position={[0.67, -8.135, -1.283]} rotation={[-1.724, -0.653, -0.248]} scale={[0.587, 1, 0.905]} />
        <mesh geometry={nodes.pPlane11_lambert5_0.geometry} material={materials.lambert5} position={[-0.369, -9.389, -2.709]} rotation={[-1.28, 0.33, -0.492]} scale={[1.203, 1, 0.647]} />
        <mesh geometry={nodes.pCube34_lambert5_0.geometry} material={materials.lambert5} position={[-5.236, -0.773, -2.705]} rotation={[0, Math.PI / 2, 0]} scale={[0.533, 0.709, 0.468]} />
        <mesh geometry={nodes.pCube91_lambert5_0.geometry} material={materials.lambert5} position={[-3.233, 5.702, -1.255]} scale={[1.191, 0.653, 0.429]} />
        <mesh geometry={nodes.pCube90_lambert5_0.geometry} material={materials.lambert5} position={[-3.233, 4.932, -1.254]} scale={[1.191, 0.653, 0.429]} />
        <mesh geometry={nodes.pTorus8_lambert2_0.geometry} material={materials.lambert2} position={[-5.232, -0.638, -2.637]} rotation={[-2.824, 0.785, 0]} scale={[0.108, 0.108, 0.11]} />
        <mesh geometry={nodes.pTorus7_lambert2_0.geometry} material={materials.lambert2} position={[-5.232, -0.85, -2.784]} rotation={[2.911, 0.785, 0]} scale={[0.108, 0.108, 0.11]} />
        <mesh geometry={nodes.pTorus6_lambert2_0.geometry} material={materials.lambert2} position={[-5.232, -0.995, -2.629]} rotation={[-3.014, 0.785, 0]} scale={[0.108, 0.108, 0.11]} />
        <mesh geometry={nodes.pCube80_lambert2_0.geometry} material={materials.lambert2} position={[-3.273, 4.915, 2.84]} rotation={[-Math.PI, 0, Math.PI]} scale={[-1.448, 0.794, 0.522]} />
        <mesh geometry={nodes.pCube79_lambert2_0.geometry} material={materials.lambert2} position={[-3.273, 5.685, 2.84]} rotation={[-Math.PI, 0, Math.PI]} scale={[-1.448, 0.794, 0.522]} />
        <mesh geometry={nodes.pCylinder32_lambert2_0.geometry} material={materials.lambert2} position={[-0.683, 6.737, 0.645]} scale={[0.233, 0.239, 0.217]} />
        <mesh geometry={nodes.pCube51_lambert2_0.geometry} material={materials.lambert2} position={[-3.177, 4.996, -1.49]} scale={[1.279, 0.701, 0.461]} />
        <mesh geometry={nodes.pCube43_lambert2_0.geometry} material={materials.lambert2} position={[3.905, 2.33, 1.749]} rotation={[0, 0, -1.018]} scale={[0.284, 0.564, 0.199]} />
        <mesh geometry={nodes.pTorus4_lambert2_0.geometry} material={materials.lambert2} position={[0.337, -8.292, -1.438]} rotation={[-Math.PI / 2, 0, 0.24]} scale={[0.137, 0.137, 0.148]} />
        <mesh geometry={nodes.pCube44_lambert2_0.geometry} material={materials.lambert2} position={[3.905, 2.33, 0.935]} rotation={[0, 0, -1.018]} scale={[0.284, 0.564, 0.199]} />
        <mesh geometry={nodes.pCube50_lambert2_0.geometry} material={materials.lambert2} position={[-3.177, 5.766, -1.49]} scale={[1.279, 0.701, 0.461]} />
        <mesh geometry={nodes.sweep35_lambert2_0.geometry} material={materials.lambert2} position={[-0.188, 1.488, 0.291]} rotation={[-0.055, 0, 0]} />
        <mesh geometry={nodes.sweep33_lambert2_0.geometry} material={materials.lambert2} position={[0, 1.55, 0.65]} rotation={[-0.108, 0, 0]} />
        <mesh geometry={nodes.pCube78_lambert2_0.geometry} material={materials.lambert2} position={[-2.935, -0.965, 3.213]} scale={[1.339, 0.62, 0.757]} />
        <mesh geometry={nodes.pCylinder53_lambert2_0.geometry} material={materials.lambert2} position={[-3.054, 6.322, 1.318]} rotation={[Math.PI / 2, 0, 0]} scale={0.107} />
        <mesh geometry={nodes.pCylinder52_lambert2_0.geometry} material={materials.lambert2} position={[-3.398, 6.768, 0.961]} rotation={[0, 0, Math.PI / 2]} scale={0.178} />
        <mesh geometry={nodes.sweep32_lambert2_0.geometry} material={materials.lambert2} position={[0, 0.206, 0]} />
        <mesh geometry={nodes.sweep27_lambert2_0.geometry} material={materials.lambert2} />
        <mesh geometry={nodes.sweep26_lambert2_0.geometry} material={materials.lambert2} />
        <mesh geometry={nodes.sweep25_lambert2_0.geometry} material={materials.lambert2} />
        <mesh geometry={nodes.sweep24_lambert2_0.geometry} material={materials.lambert2} position={[0, 0.206, 0]} />
        <mesh geometry={nodes.sweep18_lambert2_0.geometry} material={materials.lambert2} position={[0.012, 0, 0]} />
        <mesh geometry={nodes.pCube52_lambert2_0.geometry} material={materials.lambert2} position={[3.401, 5.273, 2.81]} scale={[1.51, 0.699, 0.551]} />
        <mesh geometry={nodes.pCylinder36_lambert2_0.geometry} material={materials.lambert2} position={[-1.954, 6.125, -1.455]} scale={[0.103, 0.326, 0.103]} />
        <mesh geometry={nodes.pCylinder35_lambert2_0.geometry} material={materials.lambert2} position={[-2.332, 6.125, -1.455]} scale={[0.103, 0.326, 0.103]} />
        <mesh geometry={nodes.sweep17_lambert2_0.geometry} material={materials.lambert2} position={[-1.946, 10.523, -1.259]} scale={0.123} />
        <mesh geometry={nodes.sweep16_lambert2_0.geometry} material={materials.lambert2} position={[-5.101, 12.172, -0.288]} rotation={[0, 0, 0.091]} scale={0.125} />
        <mesh geometry={nodes.pSphere3_lambert2_0.geometry} material={materials.lambert2} position={[0.047, 11.609, -0.303]} rotation={[-Math.PI, -1.2, -Math.PI]} scale={0.198} />
        <mesh geometry={nodes.sweep15_lambert2_0.geometry} material={materials.lambert2} position={[-1.932, 14.749, -0.28]} rotation={[0, 0, -0.116]} scale={0.267} />
        <mesh geometry={nodes.pCylinder34_lambert2_0.geometry} material={materials.lambert2} position={[-3.881, 7.618, -0.377]} scale={[0.05, 0.055, 0.05]} />
        <mesh geometry={nodes.pCylinder33_lambert2_0.geometry} material={materials.lambert2} position={[-0.683, 6.953, -0.033]} scale={[0.233, 0.239, 0.217]} />
        <mesh geometry={nodes.pCube48_lambert2_0.geometry} material={materials.lambert2} position={[-1.508, 9.293, 0.346]} scale={[0.278, 0.19, 0.078]} />
        <mesh geometry={nodes.pCube47_lambert2_0.geometry} material={materials.lambert2} position={[-1.652, 9.959, 0.346]} scale={[0.509, 0.154, 0.042]} />
        <mesh geometry={nodes.sweep14_lambert2_0.geometry} material={materials.lambert2} position={[3.248, 2.822, 1.033]} rotation={[0, -0.942, 0.196]} scale={0.112} />
        <mesh geometry={nodes.pCube46_lambert2_0.geometry} material={materials.lambert2} position={[-0.471, 3.159, 0.746]} scale={[8.583, 1, 4.039]} />
        <mesh geometry={nodes.pCube45_lambert2_0.geometry} material={materials.lambert2} position={[-1.323, 2.607, -1.624]} scale={[0.961, 0.842, 0.504]} />
        <mesh geometry={nodes.sweep13_lambert2_0.geometry} material={materials.lambert2} position={[2.114, 8.649, 0.644]} rotation={[0, 0, -0.105]} scale={0.135} />
        <mesh geometry={nodes.pCube42_lambert2_0.geometry} material={materials.lambert2} position={[2.398, -0.377, 2.485]} scale={[1.176, 1.477, 1.804]} />
        <mesh geometry={nodes.pCube41_lambert2_0.geometry} material={materials.lambert2} position={[-2.635, 1.462, 3.292]} scale={[1.818, 0.842, 1.028]} />
        <mesh geometry={nodes.pCube23_lambert2_0.geometry} material={materials.lambert2} position={[-0.795, 6.507, 2.001]} scale={[1.95, 0.704, 1.247]} />
        <mesh geometry={nodes.pCube20_lambert2_0.geometry} material={materials.lambert2} position={[-0.851, 8.298, 2.028]} rotation={[0, -0.141, 0]} scale={[1, 0.24, 0.917]} />
        <mesh geometry={nodes.pCylinder14_lambert2_0.geometry} material={materials.lambert2} position={[-2.665, 8.175, 0.414]} rotation={[-0.103, 0, 0]} scale={[0.061, 0.06, 0.061]} />
        <mesh geometry={nodes.pCylinder13_lambert2_0.geometry} material={materials.lambert2} position={[-1.608, 8.227, 0.345]} scale={[0.061, 0.087, 0.061]} />
        <mesh geometry={nodes.pCylinder12_lambert2_0.geometry} material={materials.lambert2} position={[4.035, 2.614, 1.533]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.336, 0.628, 0.336]} />
        <mesh geometry={nodes.pTorus5_lambert2_0.geometry} material={materials.lambert2} position={[-2.82, -0.706, -3.079]} rotation={[-Math.PI, -0.078, -1.964]} scale={[0.108, 0.108, 0.11]} />
        <mesh geometry={nodes.pCylinder11_lambert2_0.geometry} material={materials.lambert2} position={[3.992, 9.298, 0.647]} rotation={[-Math.PI, Math.PI / 4, -Math.PI]} scale={[0.079, 0.06, 0.079]} />
        <mesh geometry={nodes.pCylinder10_lambert2_0.geometry} material={materials.lambert2} position={[-0.338, 9.619, 2.018]} rotation={[-0.306, -0.361, -0.988]} scale={[0.025, 0.485, 0.025]} />
        <mesh geometry={nodes.pCylinder9_lambert2_0.geometry} material={materials.lambert2} position={[-0.44, 9.532, 2.093]} rotation={[-0.244, -0.176, -0.998]} scale={[1.164, 0.251, 1.164]} />
        <mesh geometry={nodes.pCylinder8_lambert2_0.geometry} material={materials.lambert2} position={[0.047, 6.298, -0.303]} scale={[0.073, 0.212, 0.073]} />
        <mesh geometry={nodes.pCylinder7_lambert2_0.geometry} material={materials.lambert2} position={[-6.841, 7.78, 0.059]} rotation={[0, 0.137, Math.PI / 2]} scale={[0.074, 1.12, 0.074]} />
        <mesh geometry={nodes.pCylinder6_lambert2_0.geometry} material={materials.lambert2} position={[-4.252, 7.252, 0.177]} rotation={[0, -0.257, Math.PI / 2]} scale={[0.083, 1.527, 0.083]} />
        <mesh geometry={nodes.pCylinder5_lambert2_0.geometry} material={materials.lambert2} position={[-4.418, 7.957, -0.124]} rotation={[0.229, 0.266, 0.873]} scale={[0.084, 1.527, 0.084]} />
        <mesh geometry={nodes.pCylinder3_lambert2_0.geometry} material={materials.lambert2} position={[3.332, 7.63, -0.432]} scale={[0.302, 0.72, 0.302]} />
        <mesh geometry={nodes.pCube11_lambert2_0.geometry} material={materials.lambert2} position={[4.968, 9.188, 0.647]} scale={[2.345, 0.183, 0.203]} />
        <mesh geometry={nodes.pCube10_lambert2_0.geometry} material={materials.lambert2} position={[4.968, 6.734, 0.647]} scale={[2.345, 0.183, 0.203]} />
        <mesh geometry={nodes.pCube9_lambert2_0.geometry} material={materials.lambert2} position={[6.724, 6.959, 0.719]} scale={[1.596, 5.113, 0.641]} />
        <mesh geometry={nodes.pCube8_lambert2_0.geometry} material={materials.lambert2} position={[3.588, 6.945, 1.935]} scale={[1.283, 1.982, 1.379]} />
        <mesh geometry={nodes.pCube7_lambert2_0.geometry} material={materials.lambert2} position={[-2.251, 6.891, 0.403]} scale={[2.318, 1.887, 1.674]} />
        <mesh geometry={nodes.pTorus1_lambert2_0.geometry} material={materials.lambert2} position={[-5.976, 8.494, 0]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={[1.066, 0.818, 0.941]} />
        <mesh geometry={nodes.pCube6_lambert2_0.geometry} material={materials.lambert2} position={[3.705, 7.145, -0.222]} scale={[1.847, 1.729, 1.449]} />
        <mesh geometry={nodes.pCube5_lambert2_0.geometry} material={materials.lambert2} position={[0.123, 5.892, 0.703]} scale={[8.287, 3.04, 3.38]} />
        <mesh geometry={nodes.pCube4_lambert2_0.geometry} material={materials.lambert2} position={[0.413, 2.926, 0.703]} scale={[9.641, 0.35, 7.207]} />
        <mesh geometry={nodes.polySurface1_lambert2_0.geometry} material={materials.lambert2} position={[-0.225, 1.389, 0.414]} scale={[8.108, 3.04, 3.623]} />
        <mesh geometry={nodes.pCube34_lambert2_0.geometry} material={materials.lambert2} position={[-5.236, -0.773, -2.705]} rotation={[0, Math.PI / 2, 0]} scale={[0.574, 0.764, 0.504]} />
        <mesh geometry={nodes.pTorus3_lambert2_0.geometry} material={materials.lambert2} position={[1.362, -4.582, -2.508]} rotation={[0.641, -0.667, 2.026]} scale={[0.119, 0.116, 0.116]} />
        <mesh geometry={nodes.pCylinder20_lambert2_0.geometry} material={materials.lambert2} position={[1.441, -4.226, -2.703]} rotation={[0.947, -0.498, 0.586]} scale={[0.189, 0.189, 0.149]} />
        <mesh geometry={nodes.pCube27_lambert2_0.geometry} material={materials.lambert2} position={[4.612, -1.659, 0.903]} scale={[0.378, 1, 0.281]} />
        <mesh geometry={nodes.pCube18_lambert2_0.geometry} material={materials.lambert2} position={[4.282, -1.684, 2.725]} rotation={[0, 0, -0.119]} scale={[0.212, 0.176, 0.242]} />
        <mesh geometry={nodes.pCylinder16_lambert2_0.geometry} material={materials.lambert2} position={[3.112, -1.712, -1.577]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.402, 1.184, 0.402]} />
        <mesh geometry={nodes.pCube28_lambert2_0.geometry} material={materials.lambert2} position={[4.612, -1.659, 2.155]} scale={[0.378, 1, 0.281]} />
        <mesh geometry={nodes.pCube31_lambert2_0.geometry} material={materials.lambert2} position={[-4.331, -1.526, 0.584]} rotation={[0, Math.PI / 2, 0]} scale={[0.588, 0.622, 0.187]} />
        <mesh geometry={nodes.pCube40_lambert2_0.geometry} material={materials.lambert2} position={[-2.997, -2.822, 1.25]} rotation={[-Math.PI, -0.202, -Math.PI]} scale={[0.147, 0.684, 0.187]} />
        <mesh geometry={nodes.pCylinder37_lambert2_0.geometry} material={materials.lambert2} position={[-2.084, -3.012, -1.487]} rotation={[0, -0.475, 0]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder26_lambert2_0.geometry} material={materials.lambert2} position={[-2.845, -2.594, -2.202]} rotation={[0, -0.475, 0]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder25_lambert2_0.geometry} material={materials.lambert2} position={[-3.372, -3.318, -2.065]} rotation={[-0.753, 0.156, -1.49]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder22_lambert2_0.geometry} material={materials.lambert2} position={[0.542, -1.43, -2.229]} rotation={[-0.475, 0, -Math.PI / 2]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder27_lambert2_0.geometry} material={materials.lambert2} position={[-2.084, -2.187, -1.487]} rotation={[0, -0.475, 0]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder23_lambert2_0.geometry} material={materials.lambert2} position={[-0.223, -1.415, -2.221]} rotation={[-0.475, 0, -Math.PI / 2]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCylinder39_lambert2_0.geometry} material={materials.lambert2} position={[-1.951, 6.267, -1.45]} rotation={[0, -0.475, 0]} scale={[0.169, 0.084, 0.169]} />
        <mesh geometry={nodes.pCylinder55_lambert2_0.geometry} material={materials.lambert2} position={[-4.24, 6.301, 0.966]} rotation={[0, -0.475, 0]} scale={[0.2, 0.099, 0.2]} />
        <mesh geometry={nodes.pCylinder56_lambert2_0.geometry} material={materials.lambert2} position={[-2.321, 5.869, -1.45]} rotation={[0, -0.475, 0]} scale={[0.169, 0.084, 0.169]} />
        <mesh geometry={nodes.pCube38_lambert2_0.geometry} material={materials.lambert2} position={[2.363, -1.746, 1.517]} rotation={[-Math.PI, 1.387, -Math.PI]} scale={[0.588, 0.997, 0.187]} />
        <mesh geometry={nodes.pCylinder38_lambert2_0.geometry} material={materials.lambert2} position={[1.467, -7.261, 1.262]} rotation={[-0.692, -0.771, -2.095]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCube30_lambert2_0.geometry} material={materials.lambert2} position={[-5.132, -1.526, 0.584]} rotation={[0, Math.PI / 2, 0]} scale={[0.588, 0.622, 0.187]} />
        <mesh geometry={nodes.pCube24_lambert2_0.geometry} material={materials.lambert2} position={[0.681, -8.044, -0.412]} rotation={[0, 0.339, 0]} scale={[0.069, 0.571, 0.11]} />
        <mesh geometry={nodes.pSphere2_lambert2_0.geometry} material={materials.lambert2} position={[1.827, -10.231, -0.565]} rotation={[0, 0, -1.75]} scale={[0.084, 0.093, 0.084]} />
        <mesh geometry={nodes.pCylinder17_lambert2_0.geometry} material={materials.lambert2} position={[1.027, -10.107, -0.564]} rotation={[-0.021, -0.115, -1.751]} scale={[0.269, 0.153, 0.269]} />
        <mesh geometry={nodes.pCube29_lambert2_0.geometry} material={materials.lambert2} position={[0.87, -8.232, -0.448]} scale={[0.229, 0.948, 0.229]} />
        <mesh geometry={nodes.pSphere1_lambert2_0.geometry} material={materials.lambert2} position={[0.659, -5.397, -0.761]} rotation={[-Math.PI / 2, 0, 0.154]} scale={[0.535, 0.455, 0.535]} />
        <mesh geometry={nodes.sweep19_lambert2_0.geometry} material={materials.lambert2} position={[3.036, -2.895, 0.351]} rotation={[0.011, -0.307, 0.036]} scale={0.308} />
        <mesh geometry={nodes.pCube22_lambert2_0.geometry} material={materials.lambert2} position={[1.512, -10.195, -0.565]} rotation={[0, 0, -0.179]} scale={[0.962, 0.024, 0.024]} />
        <mesh geometry={nodes.pCube21_lambert2_0.geometry} material={materials.lambert2} position={[0.794, -9.09, -0.552]} scale={[0.11, 0.611, 0.11]} />
        <mesh geometry={nodes.sweep10_lambert2_0.geometry} material={materials.lambert2} position={[9.181, -2.833, 1.738]} rotation={[0, -0.238, 0]} scale={0.343} />
        <mesh geometry={nodes.sweep22_lambert2_0.geometry} material={materials.lambert2} position={[5.813, -5.624, -2.968]} rotation={[0, 0.495, 0]} scale={[0.172, 0.172, 0.13]} />
        <mesh geometry={nodes.pCylinder2_lambert2_0.geometry} material={materials.lambert2} position={[-1.073, -4.07, -0.758]} scale={[0.615, 0.855, 0.615]} />
        <mesh geometry={nodes.sweep11_lambert2_0.geometry} material={materials.lambert2} position={[0.541, -1.425, 3.145]} rotation={[0, -0.886, -0.304]} scale={0.172} />
        <mesh geometry={nodes.sweep8_lambert2_0.geometry} material={materials.lambert2} position={[5.319, -1.904, 1.877]} rotation={[0, -0.107, 0]} scale={[0.281, 0.358, 0.358]} />
        <mesh geometry={nodes.sweep30_lambert2_0.geometry} material={materials.lambert2} />
        <mesh geometry={nodes.pCube14_lambert2_0.geometry} material={materials.lambert2} position={[3.655, -4.495, 1.066]} rotation={[0, 0.174, 0]} scale={[0.267, 1.376, 1.38]} />
        <mesh geometry={nodes.pCube32_lambert2_0.geometry} material={materials.lambert2} position={[0.031, -3.267, -1.14]} rotation={[0, 0.355, 0]} scale={[1.534, 0.104, 0.895]} />
        <mesh geometry={nodes.pCube26_lambert2_0.geometry} material={materials.lambert2} position={[4.612, -1.659, -1.903]} scale={[0.378, 1, 0.281]} />
        <mesh geometry={nodes.sweep4_lambert2_0.geometry} material={materials.lambert2} position={[4.556, -2.003, 2.732]} rotation={[0, -0.51, 0]} scale={0.435} />
        <mesh geometry={nodes.sweep9_lambert2_0.geometry} material={materials.lambert2} position={[6.91, -1.563, 7.953]} rotation={[0, -0.887, 0]} scale={0.427} />
        <mesh geometry={nodes.pCylinder19_lambert2_0.geometry} material={materials.lambert2} position={[1.441, -4.226, -2.703]} rotation={[0.947, -0.498, 0.586]} scale={[0.189, 0.189, 0.149]} />
        <mesh geometry={nodes.sweep29_lambert2_0.geometry} material={materials.lambert2} />
        <mesh geometry={nodes.pCube19_lambert2_0.geometry} material={materials.lambert2} position={[4.282, -1.684, -2.477]} rotation={[0, 0, -0.119]} scale={[0.212, 0.176, 0.253]} />
        <mesh geometry={nodes.pCube33_lambert2_0.geometry} material={materials.lambert2} position={[-0.256, -4.381, -1.459]} rotation={[0, 0.671, 0]} scale={[1.534, 0.114, 0.895]} />
        <mesh geometry={nodes.pCylinder28_lambert2_0.geometry} material={materials.lambert2} position={[-2.638, -4.599, -1.05]} scale={[0.042, 1, 0.042]} />
        <mesh geometry={nodes.pCube35_lambert2_0.geometry} material={materials.lambert2} position={[-2.937, -3.573, 1.625]} rotation={[0, 0.174, 0]} scale={[0.967, 0.967, 1.548]} />
        <mesh geometry={nodes.sweep3_lambert2_0.geometry} material={materials.lambert2} position={[4.002, -0.669, -5.122]} rotation={[0, 0.514, 0]} scale={0.79} />
        <mesh geometry={nodes.sweep12_lambert2_0.geometry} material={materials.lambert2} position={[4.562, -0.058, 3.077]} rotation={[0.008, -0.523, -0.012]} scale={0.28} />
        <mesh geometry={nodes.pTorus2_lambert2_0.geometry} material={materials.lambert2} position={[1.362, -4.582, -2.508]} rotation={[0.641, -0.667, 2.026]} scale={[0.119, 0.116, 0.116]} />
        <mesh geometry={nodes.pCube12_lambert2_0.geometry} material={materials.lambert2} position={[1.684, -3.705, -1.522]} rotation={[0, -0.825, 0]} scale={[2.887, 1.895, 0.285]} />
        <mesh geometry={nodes.pCube25_lambert2_0.geometry} material={materials.lambert2} position={[3.48, -4.843, 1.125]} rotation={[0, 0.168, 0]} scale={[0.162, 0.264, 0.186]} />
        <mesh geometry={nodes.pCylinder15_lambert2_0.geometry} material={materials.lambert2} position={[3.961, -1.712, -1.577]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.402, 1.184, 0.402]} />
        <mesh geometry={nodes.pCube16_lambert2_0.geometry} material={materials.lambert2} position={[4.606, -1.58, 0.429]} scale={[0.177, 0.839, 4.576]} />
        <mesh geometry={nodes.sweep20_lambert2_0.geometry} material={materials.lambert2} position={[7.6, -2.402, -0.303]} scale={0.291} />
        <mesh geometry={nodes.sweep7_lambert2_0.geometry} material={materials.lambert2} position={[3.078, -2.392, 11.113]} rotation={[-Math.PI, -1.497, -Math.PI]} scale={0.399} />
        <mesh geometry={nodes.sweep2_lambert2_0.geometry} material={materials.lambert2} position={[3.56, -4.201, -2.825]} rotation={[-0.073, 0.3, -0.019]} scale={0.566} />
        <mesh geometry={nodes.pCube13_lambert2_0.geometry} material={materials.lambert2} position={[3.655, -2.896, 1.066]} rotation={[0, 0.174, 0]} scale={[0.242, 0.839, 1.38]} />
        <mesh geometry={nodes.sweep6_lambert2_0.geometry} material={materials.lambert2} position={[7.288, 0.086, 10.724]} rotation={[0, -1.198, 0]} scale={0.816} />
        <mesh geometry={nodes.pCylinder54_lambert2_0.geometry} material={materials.lambert2} position={[-3.208, -2.86, -0.969]} rotation={[Math.PI / 2, 0, 0.034]} scale={0.185} />
        <mesh geometry={nodes.sweep23_lambert2_0.geometry} material={materials.lambert2} position={[3.792, -2.385, 5.815]} rotation={[0, -Math.PI / 2, 0]} scale={0.114} />
        <mesh geometry={nodes.pCylinder31_lambert2_0.geometry} material={materials.lambert2} position={[-0.692, -3.807, -1.692]} rotation={[0, 0.445, Math.PI / 2]} scale={[0.102, 1, 0.102]} />
        <mesh geometry={nodes.pCube36_lambert2_0.geometry} material={materials.lambert2} position={[-2.491, -1.857, 1.604]} rotation={[0, 0.174, 0]} scale={[1.825, 1.305, 2.409]} />
        <mesh geometry={nodes.pCylinder29_lambert2_0.geometry} material={materials.lambert2} position={[0.239, -2.784, 1.947]} scale={[0.615, 1.205, 0.615]} />
        <mesh geometry={nodes.pCube17_lambert2_0.geometry} material={materials.lambert2} position={[0.243, -1.807, -1.481]} scale={[2.206, 1.211, 0.437]} />
        <mesh geometry={nodes.sweep5_lambert2_0.geometry} material={materials.lambert2} position={[0, 0, 7.422]} />
        <mesh geometry={nodes.pCylinder18_lambert2_0.geometry} material={materials.lambert2} position={[-2.776, -3.652, -1.472]} rotation={[0, -0.291, 0]} scale={[0.53, 1, 0.53]} />
        <mesh geometry={nodes.sweep31_lambert2_0.geometry} material={materials.lambert2} />
        <mesh geometry={nodes.sweep1_lambert2_0.geometry} material={materials.lambert2} position={[7.591, -6.893, 4.026]} rotation={[0, -0.397, 0]} scale={0.778} />
        <mesh geometry={nodes.pCube39_lambert2_0.geometry} material={materials.lambert2} position={[-2.9, -2.822, 1.995]} rotation={[-Math.PI, -0.202, -Math.PI]} scale={[0.147, 0.684, 0.187]} />
        <mesh geometry={nodes.pCylinder21_lambert2_0.geometry} material={materials.lambert2} position={[-1.202, -2.224, -0.653]} rotation={[-Math.PI, -1.126, -Math.PI]} scale={[0.199, 0.855, 0.199]} />
        <mesh geometry={nodes.sweep28_lambert2_0.geometry} material={materials.lambert2} />
        <mesh geometry={nodes.pCylinder1_lambert2_0.geometry} material={materials.lambert2} position={[-2.79, -2.485, -1.478]} scale={[0.694, 0.965, 0.694]} />
        <mesh geometry={nodes.pCube15_lambert2_0.geometry} material={materials.lambert2} position={[-4.937, -2.36, 0.133]} scale={[2.206, 1.072, 0.896]} />
        <mesh geometry={nodes.pCylinder4_lambert2_0.geometry} material={materials.lambert2} position={[0.851, -6.174, -0.398]} scale={[0.522, 1.182, 0.522]} />
        <mesh geometry={nodes.pCube3_lambert2_0.geometry} material={materials.lambert2} position={[2.073, -3.205, 1.354]} rotation={[0, 0.174, 0]} scale={2.158} />
        <mesh geometry={nodes.pCube2_lambert2_0.geometry} material={materials.lambert2} position={[0, -1.236, 0]} scale={[9.845, 0.357, 4.91]} />
        <mesh geometry={nodes.pCylinder63_lambert2_0.geometry} material={materials.lambert2} position={[-0.409, -2.211, 1.867]} scale={[0.4, 0.785, 0.4]} />
        <mesh geometry={nodes.sweep21_lambert2_0.geometry} material={materials.lambert2} position={[6.37, -5.624, -0.815]} rotation={[0, 0.115, 0]} scale={[0.172, 0.172, 0.138]} />
        <mesh geometry={nodes.pCylinder24_lambert2_0.geometry} material={materials.lambert2} position={[-1.805, -1.376, -2.216]} rotation={[-0.475, 0, -Math.PI / 2]} scale={[0.169, 0.059, 0.169]} />
        <mesh geometry={nodes.pCube37_lambert2_0.geometry} material={materials.lambert2} position={[1.405, -1.746, 1.682]} rotation={[-Math.PI, 1.387, -Math.PI]} scale={[0.588, 0.997, 0.187]} />
      </group>
    </group>
  )
}

useGLTF.preload('/models/donut_cafe/scene.gltf')
