function ComingSoon() {
  return (
    <div id="coming-soon" className="flex flex-col w-5/6 lg:w-4/6 mx-auto h-screen font-['Roboto']">
      <div className="m-auto">
      <h1 className="text-3xl md:text-5xl lg:text-8xl font-bold text-white">COMING SOON</h1>
      </div>
    </div>
  );
}

export default ComingSoon;
